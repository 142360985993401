import { Component } from 'react';
import { updateObject } from '../../../../shared/utility';

class FormComponent extends Component {
    state = {
        form: {},
    }

    extractValueFromEvent(event) {
        let value = event.target.value;

        if (event.target.selectedOptions) {
            value = Array.from(event.target.selectedOptions, option => option.value);
        }
        
        return value;
    }

    handleChange = (event) => {
        const updatedOrderForm = updateObject(this.state.form, {
            [event.target.name]: this.extractValueFromEvent(event)
        });

        this.setState({form: updatedOrderForm});
    }

    onSubmit = (_) => {
        this.props.onSubmit(this.state.form);
    }
}

export default FormComponent;