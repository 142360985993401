import axios from 'axios';
import reduxStore from './store';
import {logout} from "./store/actions";

const dispatch = reduxStore;

const instanceGonito = axios.create({
    baseURL:  process.env.REACT_APP_GONITO_URL
});

instanceGonito.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        if(error.response.status === 401){
            dispatch(logout());
        }
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
});

export default instanceGonito;