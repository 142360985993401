import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    browsableUrl: null,
    gitUrl: null,
    dataset: null,
    error: null,
    loading: true,
}

const fetchDatasetStart = (state, action) => {
    return updateObject(state, {error: null, loading:true});
}

const fetchDatasetSuccess = (state, action) => {
    return updateObject(state, {
        browsableUrl: action.dataset.browsableUrl,
        gitUrl: action.dataset.url,
        error:null,
        loading: false,
    })
}

const fetchDatasetFail = (state, action) => {
    return updateObject(state, {error:action.error, loading: false})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DATASET_START: return fetchDatasetStart(state, action);
        case actionTypes.FETCH_DATASET_SUCCESS: return fetchDatasetSuccess(state, action);
        case actionTypes.FETCH_DATASET_FAIL: return fetchDatasetFail(state, action);
        default:
            return state;
    }
}

export default reducer;