import Navbar from 'react-bootstrap/Navbar';  
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { NavLink, Link } from 'react-router-dom';

import classes from './NavBar.css';


const navBar = (props) => (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Navbar.Brand href="/">
     <span className={classes.BrandName}>PolEval</span>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link as={Link} to="/challenges">Compete</Nav.Link>
      { process.env.NODE_ENV === 'development' ? <Nav.Link href="#home">Datasets</Nav.Link> : <></> }
      { process.env.NODE_ENV === 'development' ? <Nav.Link href="#home">Notebooks</Nav.Link> : <></> }
      { process.env.NODE_ENV === 'development' ? <Nav.Link href="#home">Communities</Nav.Link> : <></> }
      <Nav.Link as={Link} to="/prizes">Prizes</Nav.Link>
      <Nav.Link as={Link} to="/dates">Dates</Nav.Link>
      { process.env.NODE_ENV === 'development' ? <Nav.Link as={Link} to="/tags">Tags</Nav.Link> : <></> }
      <Nav.Link as={Link} to="/publications">Publication</Nav.Link>
      <Nav.Link as={Link} to="/organizers">Organizers</Nav.Link>
      <Nav.Link as={Link} to="/conferences">Other NLP Challenges and Conferences</Nav.Link>
    </Nav>
    <Nav>
    {!props.isAuth
        ? <Button href="/authorize" variant="light">Login/Register</Button>
        : <Button variant="light">
            <NavLink to="/logout">Logout { localStorage.getItem('username') }</NavLink>
          </Button>}
    </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default navBar;