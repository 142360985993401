import { Component } from 'react';
import classes from './FileUploadForm.module.css';

class FileUploadForm extends Component {

    state = {
        form: {
            file: null
        },
        typeError: false
    }

    dragOver = (e) => {
        e.preventDefault();
    }
    
    dragEnter = (e) => {
        e.preventDefault();
    }
    
    dragLeave = (e) => {
        e.preventDefault();
    }
    
    fileDrop = (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        this.fileSelected(files);
    }

    fileSelected = (files) => {
        if (files.length && this.isFileValid(files[0])) {
            this.setState({form: {file: files[0]}, typeError: false});
            this.props.onFileUpload(files[0]);
        } else if (files.length) {
            this.setState({typeError: true});
            this.onFileRemoval();
        }
    }

    isFileValid = (file) => {
        if (this.props.validTypes.indexOf(file.type) !== -1 || 
                this.props.validTypes.some(validType => file.name.split('.').pop() === validType)) {
            return true;
        }

        return false;
    }

    onUploadFile = () => {
        this.fileInput.click();
    }

    onFileRemoval = () => {
        this.setState({form: {file: null}});
        this.props.onFileUpload(null);
    }

    renderValidTypes () {
        return this.props.validTypes.map((type, _) => type + " ");
    }

    renderDropBlock (children, onFileUpload) {
        let error = <></>;
        let dropBlockClass = classes.FileUploadDrop;

        if (this.props.validated && this.state.form.file) {
            dropBlockClass = classes.FileUploadDropValid;
        } else if (this.props.validated) {
            dropBlockClass = classes.FileUploadDropInvalid;
            error = <div className="text-danger">
                        &nbsp;File not uploaded.
                    </div>;
        }

        return (
            <div className={dropBlockClass}>
                <input type="file" 
                    className="d-none" 
                    ref={e => this.fileInput = e}
                    onChange={e => this.fileSelected(e.target.files)}
                    accept={this.props.validTypes} />
                <div onDragOver={this.dragOver}
                    onDragEnter={this.dragEnter}
                    onDragLeave={this.dragLeave}
                    onDrop={this.fileDrop}
                    onClick={onFileUpload}>
                    <div className="text-center">
                        {children}
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    renderError () {
        let error = <></>;

        if (this.state.typeError) {
            error = <div className="text-danger">
                        &nbsp;Invalid file format. Try again.
                    </div>;
        }

        return error;
    }

    renderOptional () {
        let optional = <></>;

        if (this.props.optional) {
            const optionalText = this.props.optional === true ? '(Optional)' : '(Recommended)';

            optional = <div className="text-muted">
                            &nbsp;{ optionalText }
                        </div>;
        }

        return optional;
    }

    renderNoFile () {
        return this.renderDropBlock(
            <div>
                <div className="fa fa-upload text-primary">
                </div>
                <div className="text-primary">
                    &nbsp;Drag and Drop file here or click on the field to upload.
                </div>
                <div className="text-muted">
                    &nbsp;Types: {this.renderValidTypes()}
                </div>
                { this.renderError() }
                { this.renderOptional() }
            </div>,
            this.onUploadFile);
    }

    renderFileInfo () {
        return this.renderDropBlock(
            <div>
                <div className="fa fa-upload text-primary">
                    &nbsp;{this.state.form.file.name}&nbsp;
                </div>
                <div className="fa fa-times text-primary"
                    onClick={this.onFileRemoval} />
            </div>,
        () => void 0);
    }

    render () {
        if (this.state.form.file == null) {
            return this.renderNoFile();
        } else {
            return this.renderFileInfo();
        }
    }
}

export default FileUploadForm;