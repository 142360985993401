import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Publications.module.css';
import PublicationsMain from './PublicationsMain/PublicationsMain';
import Header from '../../components/UI/Header/Header';


class Publications extends Component {
     
    render () {
        const attachedClasses = [classes.PublicationsSection, "align-items-end"];

        return (
            <>
                <Header title="PUBLICATION" icon="fa fa-fw fa-book"></Header>
                <div className={classes.LighterPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <PublicationsMain />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default Publications;