import * as actionTypes from './actionTypes';
import axiosGonito from '../../axios-gonito';
import axiosPoleval from '../../axios-python';
import {logout} from "../actions";


export const submitSolutionStart = () => {
    return {
        type: actionTypes.SUBMIT_SOLUTION_START
    }
}

export const submitSolutionSuccess = (submissionId) => {
    return {
        type: actionTypes.SUBMIT_SOLUTION_SUCCESS,
        submissionId: submissionId,
        showModal: true
    }
}

export const submitSolutionFail = (err) => {
    return {
        type: actionTypes.SUBMIT_SOLUTION_FAIL,
        error: err,
    }
}

export const submitSolution = (data, token, handleShowLog) => {
     return dispatch => {
            dispatch(submitSolutionStart());

            if (data.type === 'git') {
                submitGitSolution(dispatch, data, token, handleShowLog)
            } else {
                submitFileSolution(dispatch, data, token, handleShowLog)
            }
     }
}

const submitGitSolution = (dispatch, data, token, handleShowLog) => {
    const params = new URLSearchParams({
        'f3': data.formData.repoUrl,
        'f4': data.formData.branch
    });

    if (data.formData.description.trim() !== '') {
        params.append('f1', data.formData.description);
    }

    if (data.formData.tags.length !== 0) {
        params.append('f2', data.formData.tags.join());
    }

    if (data.formData.remoteSpecification.trim() !== '') {
        params.append('f5', data.formData.remoteSpecification);
    }

    axiosGonito.post(`challenge-submission/${data.challengeName}`,
        params,
        {headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }}
    ).then(_ => {
        dispatch(submitSolutionSuccess(_['data']));
        handleShowLog();
    }).catch(err => {
        console.log(err);
        dispatch(logout());
        dispatch(submitSolutionFail(err));
    });
}

const mapFilesDataToFilesForm = (data) => {
    const formData = new FormData();
    const dirNames = {};

    Object.values(data.formData.files).forEach(fileInfo => {
        if (fileInfo.file !== null) {
            const splittedPath = fileInfo.path.split('/');
            const fileName = splittedPath[splittedPath.length-1];
            const dirPath = splittedPath.slice(0,-1).join('/');
    
            const file = new File([fileInfo.file], fileName);

            formData.append('files', file);
            dirNames[fileName] = dirPath;
        }
    });

    formData.append('challenge_name', data.challengeName);
    formData.append('dir_names', JSON.stringify(dirNames));

    return formData;
}

const submitFileSolution = (dispatch, data, token, handleShowLog) => {
    const formData = mapFilesDataToFilesForm(data);
    const django_token = localStorage.getItem('token');

    axiosPoleval.post(`submission/`,
        formData,
        {headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': `Bearer ${django_token}`
        }, timeout: 300000}
    ).then(res => {
        data.formData.repoUrl = res.data.sshurl;
        data.formData.branch = 'master';
        data.formData.remoteSpecification = '';

        submitGitSolution(dispatch, data, token, handleShowLog);
    }).catch(err => {
        dispatch(logout());
        dispatch(submitSolutionFail(err));
    });
}
