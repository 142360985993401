import React, {Component} from 'react';

import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import Container from 'react-bootstrap/Container';
import Alert from "react-bootstrap/Alert";

import axiosGonito from "../../../axios-gonito";


class ChallengeDeadline extends Component {

     state = {
         challengeVersionHash: null,
         challengeDeadline: null,
         challengeRemainingTime: null,
         deadlineTimeStart: null,
         isTimerSet: false,
         time: null,
         loading: true,
         error: false,
     };

     constructor(props) {
        super(props);
        this.onTimerUpdate = this.onTimerUpdate.bind(this);
    }

     componentDidMount(){
        this.fetchData();
     }

    onTimerUpdate({time, duration}){
        this.setState({
        time: time,
        duration: duration
        })
    }

    getLocalTime(name, value){
         axiosGonito.get(`format-as-local-time/${value}`)
         .then(res => {
            this.setState({
                [name]: res.data
            });
        })
        .catch(err => {
            this.setState({loading: false, error:true});
            return null;
        });
    }

     componentDidUpdate(){
         if(this.state.deadlineTimeStart && this.state.challengeDeadline){
            if(!this.state.isTimerSet)
                this.setTimer();
         };
     }

    fetchCurrTime() {
        axiosGonito.get(`current-time`)
         .then(res => {
            this.setState({
                deadlineTimeStart: res.data
            });
        })
        .catch(err => {
            this.setState({loading: false, error:true});
            return null;
        });
    }

    fetchDeadlineTime(versionHash) {
         axiosGonito.get(`version-info/${versionHash}`)
         .then(res => {
            const deadline = res.data.deadline;
            this.setState({
                challengeDeadline: deadline
            })

        })
        .catch(err => {
            this.setState({loading: false, error:true});
            return null;
        });
    }

    fetchData() {
        this.fetchCurrTime();
        this.fetchVersionHashAndDeadline();
    }

    setTimer() {
        let deadlineTime = new Date(this.state.challengeDeadline);
        let currTime = new Date(this.state.deadlineTimeStart);

        if(!this.state.error){
            this.setState({
            challengeRemainingTime: deadlineTime - currTime,
            isTimerSet: true
            });
            if(deadlineTime - currTime < 0){
                this.props.setSubmissionOff(true);
            }
        };
    }

    fetchVersionHashAndDeadline() {
        axiosGonito.get(`challenge-info/${this.props.challengeName}`)
         .then(res => {
            const versionHash = res.data.version;
            this.setState({
                challengeVersionHash: versionHash,
                });
            this.fetchDeadlineTime(this.state.challengeVersionHash);
        })
        .catch(err => {
            this.setState({loading: false, error:true});
        });

     }

     render () {
        let timer;
         if (this.state.isTimerSet){
              timer = (<div>
              time left:
              <Timer active duration={this.state.challengeRemainingTime} onTimeUpdate={this.onTimerUpdate}>
               </Timer>
               <Timecode
                format="HH h mm:ss"
                time={this.state.duration - this.state.time}
                />
               </div>)

         } else {
            timer = null;
         }
        if (this.state.challengeRemainingTime < 0){
            timer = <div>Challenge ended.</div>;
        }

        if (timer) {
            return (
            <Alert variant="dark">
                {timer}
            </Alert>
            );
        } else {
            return null;
        }

    }
}

export default ChallengeDeadline;