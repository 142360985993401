import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import * as datasetActions from "../../../store/actions/dataset";
import Spinner from "../../../components/UI/Spinner/Spinner";
import classes from './Dataset.module.css';



class Dataset extends Component {

    state = {
        challengeName: this.props.match.params.challengeName,
        browsableUrl: this.props.match.params.browsableUrl,
        gitUrl: this.props.match.params.gitUrl
    }

    componentDidMount () {
        this.props.onFetchDataset(this.state.challengeName);
    }

    render () {
        let dataset = <Spinner />;
        if (this.props.browsableUrl){
           dataset = (
                <div className={classes.DatasetOverview}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="overview">
                   <Col>
                       <Row>
                           <Alert variant="info">git clone --single-branch {this.props.gitUrl} -b main</Alert>
                       </Row>
                   </Col>
                   <Col>
                   <Row>
                   <Button href={this.props.browsableUrl}>Dataset repository</Button>
                   </Row>
                   </Col>
                </Tab.Container>
                </div>
           )
        }

       return (
            <>
               <Container>
                   {dataset}
               </Container>
            </>
        );

   }
}


const mapStateToProps = state => {
    return {
        challengeName: state.challenge.challenge.name,
        browsableUrl: state.dataset.browsableUrl,
        gitUrl: state.dataset.gitUrl
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchDataset: (challengeName) => dispatch(datasetActions.fetchDataset(challengeName)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dataset));
