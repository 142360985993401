import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Organizers.module.css';
import OrganizersMain from './OrganizersMain/OrganizersMain';
import Header from '../../components/UI/Header/Header';


class Organizers extends Component {
     
    render () {
        const attachedClasses = [classes.OrganizersSection, "align-items-end"];

        return (
            <>
                <Header title="ORGANIZERS" icon="fa fa-fw fa-id-badge"></Header>
                <div className={classes.LighterPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <OrganizersMain />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default Organizers;