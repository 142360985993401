import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    tags: null,
    error: null,
    loading: true,
}

const fetchTagsStart = (state, action) => {
    return updateObject(state, {error: null, loading:true});
}

const fetchTagsSuccess = (state, action) => {
    return updateObject(state, {
        tags: action.tags,
        error:null,
        loading: false,
    })
}

const fetchTagsFail = (state, action) => {
    return updateObject(state, {error:action.error, loading: false})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TAGS_START: return fetchTagsStart(state, action);
        case actionTypes.FETCH_TAGS_SUCCESS: return fetchTagsSuccess(state, action);
        case actionTypes.FETCH_TAGS_FAIL: return fetchTagsFail(state, action);
        default:
            return state;
    }
}

export default reducer;