import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Prizes.module.css';
import PrizesMain from './PrizesMain/PrizesMain';
import Rules from './Rules/Rules';
import Questions from './Questions/Questions';
import Header from '../../components/UI/Header/Header';


class Prizes extends Component {
     
    render () {
        const attachedClasses = [classes.PrizesSection, "align-items-end"];

        return (
            <>
                <Header title="PRIZES" icon="fa fa-fw fa-usd"></Header>
                <div className={classes.LighterPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <PrizesMain />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={classes.DarkerPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <Rules />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={classes.LighterPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <Questions />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default Prizes;