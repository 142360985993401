import React, { Component } from 'react';
import ExternalMarkdown from '../../UI/ExternalMarkdown/ExternalMarkdown';

class DatesMain extends Component {

    render () {
        return (
            <>
                <ExternalMarkdown url="/static_content/dates" rest />
            </>
        );
    }
}

export default DatesMain;