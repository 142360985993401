import React, { Component } from 'react';

import Notification from '../../components/UI/Notification/Notification';


const withErrorHandler = ( WrappedComponent, axios ) => {
    return class extends Component {
        state = {
            error: null
        }

        componentWillMount () {
            axios.interceptors.request.use(req => {
                this.setState({error: null});
                return req;
            });
            axios.interceptors.response.use(res => res, error => {
                this.setState({error: error});
            });
        }

        componentWillUnmount () {
            axios.interceptors.request.eject( this.reqInterceptor );
            axios.interceptors.response.eject( this.resInterceptor );
        }


        errorConfirmedHandler = () => {
            this.setState( { error: null } );
        }

        render () {
            return (
                <>
                    <Notification type="danger" show={this.state.error} />
                    <WrappedComponent {...this.props} />
                </>
            );
        }
    }
}

export default withErrorHandler;