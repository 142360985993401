import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Dates.module.css';
import DatesMain from './DatesMain/DatesMain';
import Header from '../../components/UI/Header/Header';


class Dates extends Component {
     
    render () {
        const attachedClasses = [classes.DatesSection, "align-items-end"];

        return (
            <>
                <Header title="IMPORTANT DATES" icon="fa fa-fw fa-calendar"></Header>
                <div className={classes.LighterPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <DatesMain />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default Dates;