import React, { Component } from 'react';
import ExternalMarkdown from '../../UI/ExternalMarkdown/ExternalMarkdown';

class OrganizersMain extends Component {

    render () {
        return (
            <>
                <ExternalMarkdown url="/static_content/organizers" rest />
            </>
        );
    }
}

export default OrganizersMain;