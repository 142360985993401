import React, {Component} from 'react';
import { Route, withRouter } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import Logo from '../../Logo/Logo';
import classes from "./SideBar.module.css";

class Sidebar extends Component {
    state = {
        expanded: true
    }
    render() {
        return (
                 <Route render={({ location, history }) => (
                      <React.Fragment>
                        <SideNav className={classes.SideBar}
                            expanded={this.state.expanded}
                            onSelect={(selected) => {
                                const to = '/' + selected;
                                if (location.pathname !== to) {
                                    history.push(to);
                                }
                            }}
                            onToggle={(expanded) => {
                            this.setState({ expanded });
                            this.props.sideBarToggleHandler()
                        }}> 
                        <SideNav.Toggle />
                        <SideNav.Nav className={classes.SideNav} >
                            <NavItem eventKey="home"> 
                                <NavIcon>
                                    <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Home
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="challenges">
                                <NavIcon>
                                    <i className="fa fa-fw fa-trophy" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Compete
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="datasets">
                                <NavIcon>
                                    <i className="fa fa-fw fa-database" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Datasets
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="notebooks">
                                <NavIcon>
                                    <i className="fa fa-fw fa-file-code-o" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Notebooks
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="tags">
                                <NavIcon>
                                    <i className="fa fa-fw fa-tags" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Tags
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="communities">
                                <NavIcon>
                                    <i className="fa fa-fw fa-comments" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Communities
                                </NavText>
                            </NavItem> 
                            <NavItem eventKey="conferences">
                                <NavIcon>
                                    <i className="fa fa-fw fa-users" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Conferences
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="prizes">
                                <NavIcon>
                                    <i className="fa fa-fw fa-usd" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Prizes
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="dates">
                                <NavIcon>
                                    <i className="fa fa-fw fa-calendar" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Dates
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="publications">
                                <NavIcon>
                                    <i className="fa fa-fw fa-book" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Publications
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="organizers">
                                <NavIcon>
                                    <i className="fa fa-fw fa-id-badge" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Organizers
                                </NavText>
                            </NavItem>
                            <Logo show={this.state.expanded}/>
                        </SideNav.Nav>
                        </SideNav>
                    </React.Fragment>
                )} />
    );
}
}

export default withRouter(Sidebar);
