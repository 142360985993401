import * as actionTypes from './actionTypes';
import axiosGonito from '../../axios-gonito';


export const fetchTagsStart = () => {
    return {
        type: actionTypes.FETCH_TAGS_START
    }
}

export const fetchTagsSuccess = (tags) => {
    return {
        type: actionTypes.FETCH_TAGS_SUCCESS,
        tags: tags,
    }
}

export const fetchTagsFail = (err) => {
    return {
        type: actionTypes.FETCH_TAGS_FAIL,
        error: err,
    }
}

export const fetchTags = () => {
    return dispatch => {
        dispatch(fetchTagsStart());
        axiosGonito.get(`list-tags`)
        .then(res => {
            const tags = res.data;
            dispatch(fetchTagsSuccess(tags));
        })
        .catch(err => {
            dispatch(fetchTagsFail(err));
        });
    }
}
