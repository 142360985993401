import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import classes from './Spinner.module.css';

const spinner = (props) => (
    <Spinner id={props.key} className={classes.Spinner} animation="border" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
);

export default spinner;