import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Container from 'react-bootstrap/Container';

import classes from './ChallengeOverview.module.css';
import axiosGonito from "../../../axios-gonito";
import Spinner from "../../../components/UI/Spinner/Spinner";


class ChallengeOverview extends Component {

     state = {
         challengeOverview:null,
         loading:true,
         error:false,
     };


     componentDidMount() {
         this.fetchOverview();
     }


    fetchOverview() {
        axiosGonito.get(`challenge-readme/${this.props.challengeName}/markdown`)
         .then(res => {
            const challengeOverview = res.data;
            this.setState({
                loading: false,
                challengeOverview: challengeOverview,
                });
        })
        .catch(err => {
            this.setState({loading: false, error:true});
        });

     }


    render () {
         let spinner = <Spinner/>
         if (!this.state.loading){
             spinner = null;
         }

         return (
             <Container>
                 {spinner}
                 <div className={classes.ChallengeOverview}>
                     <ReactMarkdown plugins={[gfm]} children={this.state.challengeOverview} />
                 </div>
             </Container>
         );
    }
}

export default ChallengeOverview;