import Form from 'react-bootstrap/Form';
import { updateObject } from '../../../shared/utility';
import FormComponent from '../../../components/UI/Form/FormComponent/FormComponent';
import SubmissionForm from '../SubmissionForm/SubmissionForm';

class GitSubmissionForm extends FormComponent {
    state = {
        form: {
            repoUrl: '',
            branch: 'master',
            remoteSpecification: '',
        }
    }

    onSubmit = (form) => {
        const updatedForm = updateObject(this.state.form, form);
        this.props.onSubmit(updatedForm);
    }

    render () {
        return (
            <SubmissionForm
                submitting={this.props.submitting} 
                success={this.props.success} 
                error={this.props.error}
                onSubmit={this.onSubmit}>

                <Form.Group>
                    <Form.Label>Repository url</Form.Label>
                    <Form.Control name="repoUrl" type="url" placeholder="Enter url" defaultValue={this.state.form.repoUrl} required
                     onChange={this.handleChange} />
                     <Form.Control.Feedback type="invalid">
                        Please provide a valid url.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group >
                    <Form.Label>Repository branch name</Form.Label>
                    <Form.Control name='branch' type="text" placeholder="Enter branch" defaultValue={this.state.form.branch} required
                      onChange={this.handleChange} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a repository branch name.
                    </Form.Control.Feedback>
                  </Form.Group>

                {/* <Form.Group>
                    <Form.Label>git-annex remote specification</Form.Label>
                    <Form.Control name="remoteSpecification" type="text" 
                        placeholder="Enter git-annex remote specification (optional)" defaultValue={this.state.form.remoteSpecification}
                        onChange={this.handleChange} />
                </Form.Group> */}
                
            </SubmissionForm>
        );
    }
}

export default GitSubmissionForm;