import axios from 'axios';
import reduxStore from './store';
import {authStateCheck} from "./store/actions";


const dispatch = reduxStore;

const instancePolEval = axios.create({
    baseURL: process.env.REACT_APP_POLEVAL_URL
});

instancePolEval.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        if(error.response.status === 401){
            dispatch(authStateCheck());
        }
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
});

export default instancePolEval;