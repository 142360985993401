import * as actionTypes from './actionTypes';
import axiosGonito from '../../axios-gonito';


export const fetchChallengeStart = () => {
    return {
        type: actionTypes.FETCH_CHALLENGE_START
    }
}

export const fetchChallengeSuccess = (challenge) => {
    return {
        type: actionTypes.FETCH_CHALLENGE_SUCCESS,
        challenge: challenge,
    }
}

export const fetchChallengeFail = (err) => {
    return {
        type: actionTypes.FETCH_CHALLENGE_FAIL,
        error: err,
    }
}

export const fetchChallenge = (name) => {
    return dispatch => {
        dispatch(fetchChallengeStart());
        axiosGonito.get(`challenge-info/${name}`)
        .then(res => {
            const challenge = res.data;
            dispatch(fetchChallengeSuccess(challenge));
        })
        .catch(err => {
            dispatch(fetchChallengeFail(err));
        });
    }
}
