import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';

import classes from './HomeAuthenticated.module.css';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';
import Home from '../Home';

class HomeAuthenticated extends Component {

    state = {
        seconds: 10,
        timer: null
    }
   
    
    componentDidMount() {
        if(this.props.error){
            this.inter = setInterval(() => {
            if (this.state.seconds <= 0) {
                clearInterval(this.inter);
                this.props.onLogout(this.props.keycloak)
            } else {
                this.setState((prevState) => ({seconds: prevState.seconds - 1})); 
            }
            }, 1000);
        }
      }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.error && this.props.error !== nextState.error;
    }
    
      
    componentWillUnmount() {
        if(this.props.error){
            clearInterval(this.inter);
        }
      }
      
 
    render () {
        const email = 'poleval.competitions@gmail.com'
        let errorMessage = null;
        if(this.props.error){
            errorMessage = (
                <>
                    <Alert variant="danger">
                    Oops..Something went wrong. Please <Alert.Link href={`mailto:${email}`}>let us know </Alert.Link> 
                    about the error and try again later.
                    </Alert>
                    <p>You will be logged out in {this.state.seconds} seconds </p>
                </>
            )
        }

        let content = null;
        if(this.props.isAuthenticated && !this.props.error){
            content = <>
                        <Home />
                    </>
        }
        return (
            <>
                { errorMessage }
                { content }
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token != null,
        username: state.auth.username,
        keycloak: state.auth.keycloak
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (keycloak) => dispatch(actions.logout(keycloak)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(HomeAuthenticated, axios));
