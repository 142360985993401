import Form from 'react-bootstrap/Form';
import { updateObject } from '../../../shared/utility';
import FormComponent from '../../../components/UI/Form/FormComponent/FormComponent';
import SubmissionForm from '../SubmissionForm/SubmissionForm';
import FileUploadForm from '../../../components/UI/Form/FileUploadForm/FileUploadForm';
import axiosGonito from "../../../axios-gonito";

class FileSubmissionForm extends FormComponent {
    state = {
        form: {
            files: {
                'dev-0': {
                    type: 'optional',
                    file: null,
                    path: 'dev-0/dev-0',
                    description: 'Development file set 0',
                },
                'test-0': {
                    type: 'obligatory',
                    file: null,
                    path: 'test-0/test-0',
                    description: 'Test file set 0',
                }
            },
        },
        validated: false
    }

    componentDidUpdate = (prevProps, _) => {
        if (this.props.success && !prevProps.success) {
            this.reset();
        }
    }

    componentDidMount() {
        this.fetchTests();
    }

    reset = () => {
        this.setState({validated: false});
    }

    onSubmit = (form) => {
        const updatedForm = updateObject(this.state.form, form);

        this.setState({validated: true});

        if (this.checkFiles()) {
            this.props.onSubmit(updatedForm);
        }
    }

    fetchTests = () => {
        axiosGonito.get(`challenge-all-submissions/${this.props.challengeName}`)
         .then(res => {
            const tests = res.data.tests;
            const files = this.mapTestsToFiles(tests);

            this.setState({
                form: {
                    files: files,
                },
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

    mapTestsToFiles = (tests) => {
        let names = tests.map(test => test.name);
        names = [...new Set(names)];

        let testIndexes = names.filter(test => test.includes('test')).map(test => test.split('-')[1]);
        let maxTest = testIndexes.reduce((r, e) => r < e ? e : r, "");

        let files = {};
        names.forEach(name => {
            let type = 'optional';
            let desc = 'Development file set';
            let index = name.split('-')[1];

            if (name.includes('test')) {
                desc = 'Test file set';

                if (maxTest == index) {
                    type = 'obligatory';
                }
            }

            desc = desc + ' ' + index;

            let file = {
                type: type,
                file: null,
                path: name + '/' + name,
                description: desc,
            };

            files[name] = file;
        });

        return files;
    }

    checkFiles = () => {
        return !Object.values(this.state.form.files).some(x => x.file === null && x.type === 'obligatory');
    }

    onFileUpload = (name, file) => {
        let files = this.state.form.files;
        files[name].file = file;

        this.setState({form: {files: files}});
    }

    render () {
        const fileForms = Object.keys(this.state.form.files).map(name => {
            const fileInfo = this.state.form.files[name];
            const isOptional = fileInfo.type !== 'obligatory';
            const isRecommended = fileInfo.type === 'recommended';

            return (<>
                <Form.Label>{fileInfo.description} ({ name }):</Form.Label>
                <FileUploadForm 
                    validTypes={ ['tsv'] }
                    onFileUpload={ file => this.onFileUpload(name, file) }
                    validated={ this.state.validated && !isOptional }
                    optional={ isRecommended ? 'r' : isOptional } />
                <p></p>
            </>);
        });

        return (
            <SubmissionForm
                submitting={this.props.submitting} 
                success={this.props.success} 
                error={this.props.error}
                onSubmit={this.onSubmit}>
                <Form.Group>
                    { fileForms }
                </Form.Group>
            </SubmissionForm>
        );
    }
}

export default FileSubmissionForm;