import React from 'react';
import Alert from 'react-bootstrap/Alert';

const notification = (props) => {
    let notification = null;
    if (props.show){
        notification =  <Alert variant={props.type}>
                            This is a alert with{' '}
                            <Alert.Link href="#">an example link</Alert.Link>. Give it a click if you
                            like.
                        </Alert>
    }
    return (
        <>
            {notification}
        </>
       
)};

export default notification;
