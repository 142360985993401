import React, {Component} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import asyncComponent from './hoc/asyncComponent/asyncComponent';

import Layout from './hoc/Layout/Layout';
import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';
import Conferences from './containers/Conferences/Conferences';
import Challenges from './containers/Challenges/Challenges';
import Challenge from './containers/Challenges/Challenge/Challenge';
import Submission from './containers/Submissions/Submission/Submission';
import Tags from './containers/Tags/Tags';
import HomeAuthenticated from './containers/Home/HomeAuthenticated/HomeAuthenticated';
import Home from './containers/Home/Home';
import Prizes from './containers/Prizes/Prizes';
import Publications from './containers/Publications/Publications';
import Dates from './containers/Dates/Dates';
import Organizers from './containers/Organizers/Organizers';

const asyncAuth = asyncComponent(() => {
    return import('./containers/Auth/Auth')
});


class App extends Component {

    componentDidMount() {
        this.props.onTryAutoSignup(this.props.keycloak);
    }

    render() {

        let routes = (
            <Switch>
                <Route path="/authorize" exact component={asyncAuth}/>
                <Route path="/challenges" component={Challenges}/>
                <Route path="/challenge/:challengeName" exact component={Challenge}/>
                <Route path="/conferences" component={Conferences}/>
                <Route path="/submission/:challengeName" component={Submission} />
                <Route path="/tags" component={Tags}/>
                <Route path="/prizes" component={Prizes} />
                <Route path="/publications" component={Publications} />
                <Route path="/dates" component={Dates}/>
                <Route path="/organizers" component={Organizers}/>
                <Route path="/" exact component={Home}/>
                <Redirect to="/"/>
            </Switch>
        );

        if (this.props.isAuthenticated) {
            routes = (
                <Switch>
                    <Route path="/" exact component={HomeAuthenticated}/>
                    <Route path="/conferences" component={Conferences}/>
                    <Route path="/challenges" component={Challenges}/>
                    <Route path="/challenge/:challengeName" exact component={Challenge}/>
                    <Route path="/submission/:challengeName" component={Submission} />
                    <Route path="/prizes" component={Prizes} />
                    <Route path="/publications" component={Publications}/>
                    <Route path="/dates" component={Dates}/>
                    <Route path="/organizers" component={Organizers}/>
                    <Route path="/logout" component={Logout}/>
                    <Redirect to="/"/>
                </Switch>
            )
        }
        return (
            <div>
                <Layout>
                    {routes}
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        isAuthenticated: state.auth.token != null,
        keycloak: state.auth.keycloak,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: (keycloak) => dispatch(actions.authStateCheck(keycloak))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
