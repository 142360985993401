import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";


import classes from './Home.module.css';
import axiosGonito from '../../axios-gonito';
import ChallengeThumbnail from "../Challenges/ChallengeThumbnail/ChallengeThumbnail";
import conferencesImg from '../../assets/images/conf.jpg';
import nlpImg from '../../assets/images/nlp.jpg';
import nlpWordsImg from '../../assets/images/nlpWords.jpeg';
import Spinner from "../../components/UI/Spinner/Spinner";
import HomeNews from "./HomeNews/HomeNews";

class Home extends Component {

    state = {
        currentChallenges: [],
        challenges: [],
        loading: false,
        error: false,
        learnMore: false,
    }

    componentDidMount() {
        this.fetchChallenges(true);
        this.fetchChallenges();
    }


    fetchChallenges = (onlyCurrent=false) => {
        var type;
        var address;
        if (onlyCurrent){
            address = `list-challenges-by-tag/`+ process.env.REACT_APP_CURR_COMPETITION_TAG;
            type = `currentChallenges`;
        } else {
            address = `list-challenges`;
            type = `challenges`;
        }
        
        this.setState({loading: true});
        axiosGonito.get(address)
            .then(res => {
                const challenges = res.data.slice(0, 6);
                this.setState({
                    loading: false,
                    [type]: challenges,
                });
            })
            .catch(err => {
                this.setState({loading: false, error: true});
            });
    };

    render() {
        let spinner = <Spinner/>
        if (!this.state.loading) {
            spinner = null;
        }

        const attachedClasses = [classes.HomeSection, "align-items-end"];
        const heartLeftIconClasses = [classes.HeartIcon, classes.HeartLeftIcon, "fa fa-heart"];
        const heartRightIconClasses = [classes.HeartIcon, classes.HeartRightIcon, "fa fa-heart"];

        let learnMore = <Button onClick={e => this.setState({learnMore: true})} className={classes.HomeButton}>LEARN MORE</Button>;
        if (this.state.learnMore) {
            learnMore = <div>
                        {/* text - more details */}
                        </div>;
        }

        return (
            <>
                <div className={classes.LighterPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={7}>
                                <h1 className={classes.HomeHeader}>PolEval :: <br/>Competitions</h1>
                                <div>
                                    PolEval is a SemEval-inspired evaluation campaign for natural language processing
                                    tools for Polish.
                                    Submitted tools compete against one another within certain tasks selected by
                                    organizers,
                                    using available data and are evaluated according to pre-established procedures.
                                </div>
                                {/* <div>
                                    { learnMore }
                                </div> */}
                                {/* <br/>
                                <h3 className={classes.HomeCurrentCompHeader}>PolEval 2022 Call for Tasks</h3>
                                <p>
                                    PolEval 2022 is open for Task proposals for this year's edition. 
                                    Please send a short description of the task to one of the
                                    <a href="/organizers/"> Organizers </a>or through <a href="https://discord.gg/dpn94tUSyT">our Discord server</a>.
                                </p> */}
                            </Col>
                            <Col sm={5}>
                                <Image src={nlpImg} fluid/>
                            </Col>
                        </Row>

                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <h1 className={classes.HomeCurrentCompHeader}>Current competitions</h1>
                                {spinner}
                                <CardDeck className={classes.CompCardDeck}>
                                    {this.state.currentChallenges.map((challenge, index) => (
                                        <ChallengeThumbnail
                                            key={index}
                                            name={challenge.name}
                                            title={challenge.title}
                                            archived={challenge.archived}
                                            description={challenge.description}
                                            imageUrl={challenge.imageUrl}
                                            small={true}
                                        />
                                    ))}
                                </CardDeck>
                                <h1 className={classes.HomeCurrentCompHeader}>Past competitions</h1>
                                {spinner}
                                <CardDeck className={classes.CompCardDeck}>
                                    {this.state.challenges
                                    .filter(challenge => !challenge.tags.some(tag => tag.name == process.env.REACT_APP_CURR_COMPETITION_TAG))
                                    .map((challenge, index) => (
                                        <ChallengeThumbnail
                                            key={index}
                                            name={challenge.name}
                                            title={challenge.title}
                                            archived={challenge.archived}
                                            description={challenge.description}
                                            imageUrl={challenge.imageUrl}
                                            small={true}
                                        />
                                    ))}
                                </CardDeck>
                                <CardDeck>
                                    <Card className={[classes.CurrentCompCard, classes.CurrentCompLeftCard].join(' ')}>
                                        <Card.Body>
                                            <Card.Title className={classes.CurrentCompetitionText}>ALL
                                                COMPETITIONS</Card.Title>
                                            <Button href="/challenges" className={classes.CurrentCompetitionButton}>ENTER</Button>
                                        </Card.Body>
                                    </Card>
                                </CardDeck>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={classes.DarkerPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={12}>
                                <HomeNews />
                            </Col>
                        </Row>
                    </Container>
                </div>
                 { process.env.NODE_ENV === 'development' ? <><div className={classes.LighterPart}>
                    <Container>
                        <Row className={attachedClasses.join(' ')}>
                            <Col sm={6}>
                                <Image src={nlpWordsImg} fluid/>
                            </Col>
                            <Col sm={6}>
                                <h1 className={[classes.HomeHeader,classes.DatasetText].join(' ')}>PolEval :: Datasets</h1>
                                <div className={classes.DatasetText}>
                                    Add a description of your offer and key benefits. What it is and how it helps your
                                    customer.
                                    Add a description of your offer and key benefits. What it is and how it helps your
                                    customer.
                                    Add a description of your offer and key benefits.
                                </div>
                                <Card className={[classes.CurrentCompCard, classes.CurrentCompRightCard].join(' ')}>
                                    <Card.Body>
                                        <Card.Title className={classes.CurrentCompetitionText}>PAST
                                            COMPETITIONS</Card.Title>
                                        <Button className={classes.CurrentCompetitionButton}>REVIEW</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                             <Col sm={12}>
                                <h1 className={classes.HomeCurrentCompHeader}>Recent datasets</h1>
                             </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Card className={classes.RecentDatasetCard}>
                                    <div className={classes.RecentDataset}>
                                      <span className={classes.RecentDatasetText}>
                                        <h5>Dataset 1</h5>
                                        <p>
                                          Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                                          ante sollicitudin commodo.
                                        </p>
                                      </span>
                                        <span className={classes.RecentDatasetIcon}>
                                        <i className="fa fa-fw fa-file-code-o" style={{fontSize: '3.75em'}}/>
                                      </span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={classes.DarkerPart}>
                    <Container>
                        <Row>
                            <Col sm={12} className={classes.CommunityHeaderCol}>
                                <h1 className={classes.HomeCommunityHeader}>PolEval :: Community</h1>
                                <h5 className={classes.CommunitySubHeader}>Join the discussion about AI research in the
                                    context of Polish NLP</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <div
                                    className={[classes.CommunityRectangular, classes.CommunityRectangular1].join(' ')}>
                                      <span className={classes.RecentDatasetIcon}>
                                         <i className={heartLeftIconClasses.join(' ')}/>
                                      </span>
                                    <span>
                                            <h5 className={classes.CommunityRectHeader}>Discussion Forum</h5>
                                            <p className={classes.CommunityRectText}>Add a description of your offer and key benefits</p>
                                      </span>
                                </div>
                                <div
                                    className={[classes.CommunityRectangular, classes.CommunityRectangular2].join(' ')}>
                                      <span className={classes.RecentDatasetIcon}>
                                        <i className={heartLeftIconClasses.join(' ')}/>
                                      </span>
                                    <span>
                                            <h5 className={classes.CommunityRectHeader}>Conference list</h5>
                                            <p className={classes.CommunityRectText}>Add a description of your offer and key benefits</p>
                                      </span>
                                </div>
                            </Col>
                            <Col sm={4} className={classes.CommunityImageCol}>
                                <Image src={conferencesImg} className={classes.CommunityImage} fluid/>
                            </Col>
                            <Col sm={4}>
                                <div
                                    className={[classes.CommunityRectangular, classes.CommunityRectangular3].join(' ')}>
                                      <span>
                                            <h5 className={classes.CommunityRectHeader}>Public notebooks</h5>
                                            <p className={classes.CommunityRectText}>Add a description of your offer and key benefits</p>
                                      </span>
                                    <span className={classes.RecentDatasetIcon}>
                                         <i className={heartRightIconClasses.join(' ')}/>
                                      </span>
                                </div>
                                <div
                                    className={[classes.CommunityRectangular, classes.CommunityRectangular4].join(' ')}>
                                      <span>
                                            <h5 className={classes.CommunityRectHeader}>Lorem ipsum</h5>
                                            <p className={classes.CommunityRectText}>Add a description of your offer and key benefits</p>
                                      </span>
                                    <span className={classes.RecentDatasetIcon}>
                                         <i className={heartRightIconClasses.join(' ')}/>
                                      </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div></> : <></> }
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token != null,
        username: state.auth.username
    }
}

export default connect(mapStateToProps, null)(Home, axios);
