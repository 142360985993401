import * as actionTypes from './actionTypes';
import axiosGonito from '../../axios-gonito';


export const fetchDatasetStart = () => {
    return {
        type: actionTypes.FETCH_DATASET_START
    }
}

export const fetchDatasetSuccess = (dataset) => {
    return {
        type: actionTypes.FETCH_DATASET_SUCCESS,
        dataset: dataset,
    }
}

export const fetchDatasetFail = (err) => {
    return {
        type: actionTypes.FETCH_DATASET_FAIL,
        error: err,
    }
}

export const fetchDataset = (name) => {
    return dispatch => {
        dispatch(fetchDatasetStart());
        axiosGonito.get(`challenge-repo/${name}`)
        .then(res => {
            const dataset = res.data;
            dispatch(fetchDatasetSuccess(dataset));
        })
        .catch(err => {
            dispatch(fetchDatasetFail(err));
        });
    }
}
