import React, { useState } from 'react';

import classes from './ColoredRow.module.css';

function ColoredRow(props) {
//  const [componentClass, setComponentClass] = useState('');

//  if(props.phase=='poleval-2021'){
//    setComponentClass('competition-submission-phase');
//  } else if(props.phase=='after-poleval-2021'){
//    setComponentClass('sandbox-submission-phase');
//  } else {
//    setComponentClass('other-submission-phase');
//  }

  let componentClass;

  if(props.phase==process.env.REACT_APP_CURR_COMPETITION_TAG){
    componentClass = classes.CompetitionSubmissionPhase;
  } else if(props.phase.startsWith('after-')){
    componentClass = classes.SandboxSubmissionPhase;
  } else {
    componentClass = classes.OtherSubmissionPhase;
  }

  return (
    <tr className={componentClass} key={props.key}>
    {props.children}
    </tr>
  );
}

export default ColoredRow;