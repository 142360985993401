import React from 'react';

import polevalLogo from '../../assets/images/logo.png';
import classes from './Logo.module.css';

const logo = (props) => {
    let attachedClasses = [classes.Logo]
    if (!props.show){
        attachedClasses = [classes.Logo, classes.Invisible]
    }
    return (
    <div className={attachedClasses.join(' ')} style={{height: props.height}}>
        <img src={polevalLogo} alt="PolEval" />
    </div>
)};

export default logo;
