import React, { Component } from 'react';
import ExternalMarkdown from '../../UI/ExternalMarkdown/ExternalMarkdown';

class Rules extends Component {

    render () {
        return (
            <>
                <h2>Rules</h2>
                <ExternalMarkdown url="/static_content/rules" rest />
            </>
        );
    }
}

export default Rules;