import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Figure from 'react-bootstrap/Figure';
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";


import Spinner from "../../../components/UI/Spinner/Spinner";
import ChallengeLeaderboard from '../ChallengeLeaderboard/ChallengeLeaderboard';
import ChallengeOverview from "../ChallengeOverview/ChallengeOverview";
import ChallengeDeadline from "../ChallengeDeadline/ChallengeDeadline";
import Dataset from "../../Datasets/Dataset/Dataset";
import Submissions from "../../Submissions/Submissions";
import classes from './Challenge.module.css';
import ShowRoleBased from '../../../hoc/ShowRoleBased/ShowRoleBased';
import * as challengeActions from "../../../store/actions";


class Challenge extends Component {

    state = {
        challengeName: this.props.match.params.challengeName,
        submissionOff: false
    }

    constructor(props) {
        super(props);
        this.setSubmissionOff = this.setSubmissionOff.bind(this);
    }

    componentDidMount () {
        this.props.onFetchChallenge(this.state.challengeName);
    }

    joinBtnClickHandler = () => {
        this.props.history.push(`/submission/${this.state.challengeName}`)
    }

    setSubmissionOff(val){
        this.setState({
            submissionOff: val
        });
    }

    render () {
         let challenge = <Spinner />;
         const joinBtnClasses = [classes.JoinBtn, "pull-right"];
         if (this.props.challenge){
             let archived = <><span>&#183;</span> &nbsp;<Badge variant="secondary"> ARCHIVED</Badge></>
             if(this.props.challenge.archived === false){
                  archived = null;
             }

            let imageUrl = this.props.challenge.imageUrl == null ? "/media/conference/nlp2.jpg" : 
                                process.env.REACT_APP_GONITO_URL + this.props.challenge.imageUrl.replace("/api/", "").replace("/api", "");

            challenge = (
                <Card className={classes.ChallengeCard}>
                        <Card.Header>
                            <Figure className={classes.Figure}>
                              <Figure.Image
                                responsive
                                src={ imageUrl }
                                fluid
                              />
                              <Figure.Caption className={classes.FigureCaption}>
                                  {this.props.challenge.title}&nbsp;
                                  {archived}
                              </Figure.Caption>
                            </Figure>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="overview">
                                <Row>
                                    <Col sm={10}>
                                      <Nav variant="tabs">
                                        <Nav.Item>
                                          <Nav.Link eventKey="overview">Overview</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="leaderboard">Leaderboard</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="submissions">Submissions</Nav.Link>
                                        </Nav.Item>
                                        <ShowRoleBased roleRequired="admin">
                                            <Nav.Item>
                                              <Nav.Link eventKey="adminonly">Admin only</Nav.Link>
                                            </Nav.Item>
                                        </ShowRoleBased>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dataset">Dataset</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={2}>
                                    <Row>
                                        <Button
                                        disabled={this.state.submissionOff}
                                        variant="dark"
                                        className={joinBtnClasses.join(' ')}
                                        onClick={() => {
                                             this.joinBtnClickHandler()
                                        }}>
                                            Submit Solution
                                        </Button>
                                        </Row>
                                        <br/>
                                        <Row>
                                        <ChallengeDeadline
                                            challengeName={this.state.challengeName}
                                            setSubmissionOff={this.setSubmissionOff}
                                        />
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Tab.Content>
                                    <Tab.Pane eventKey="overview">
                                        <ChallengeOverview challengeName={this.state.challengeName} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="leaderboard">
                                        <ChallengeLeaderboard challengeName={this.state.challengeName}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="submissions">
                                        <Submissions challengeName={this.state.challengeName}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="adminonly">
                                        <div>For demonstrational purposes only</div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dataset">
                                        <Dataset challengeName={this.state.challengeName}/>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Row>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
            )
         }

         return (
             <>
                <Container>
                    {challenge}
                </Container>
             </>
         );
    }
}


const mapStateToProps = state => {
    return {
        challenge: state.challenge.challenge,
        loading: state.challenge.loading,
        error: state.challenge.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchChallenge: (challengeName) => dispatch(challengeActions.fetchChallenge(challengeName)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Challenge));
