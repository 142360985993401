import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    username: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    keycloak: null
}

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading:true});
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.idToken,
        username: action.username,
        error:null,
        loading: false,
        keycloak: action.keycloak
    })
}

const authFail = (state, action) => {
    return updateObject(state, {error: action.error, loading:false, keycloak: action.keycloak})
}

const authLogout = (state, action) => {
    return updateObject(state, {token: null, username:null, keycloak:action.keycloak, loading:false});
}

const createUserSuccess = (state, action) => {
    return updateObject(state, {
        token: action.idToken,
        username: action.username,
        error:null,
        loading: false,
        keycloak: action.keycloak
    })
}


const createUserFail = (state, action) => {
    return updateObject(state, {error: action.error, loading:false})
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.CREATE_USER_SUCCESS: return createUserSuccess(state, action);
        case actionTypes.CREATE_USER_FAIL: return createUserFail(state, action);
        default:
            return state;
    }
    
}

export default reducer;