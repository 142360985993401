import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/auth";
import challengeReducer from "./store/reducers/challenge";
import tagsReducer from "./store/reducers/tags"
import submissionReducer from "./store/reducers/submission"
import datasetReducer from "./store/reducers/dataset"

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    challenge: challengeReducer,
    tags: tagsReducer,
    submission: submissionReducer,
    dataset: datasetReducer
});
const store = createStore(rootReducer,  composeEnhancers(
    applyMiddleware(thunk)
));

export default store;