import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';

import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal'
import Spinner from "../../../components/UI/Spinner/Spinner";

import * as actions from "../../../store/actions";
import * as actionTypes from '../../../store/actions';


class SubmissionLog extends Component {

    state = {
        submissionId: this.props.submissionId,
        isShown: this.props.isShown,
        log: [],
        messages: new Set()
    }

    componentDidMount() {
        if(this.state.submissionId && this.state.isShown){
            this.connect();
        };
    }

    componentDidUpdate(){
        if(!this.state.isShown && this.state.log){
            this.setState({
                submissionId: null,
                log: [],
                messages: new Set(),
            });
        }
    }

    componentWillUnmount(){
        if(this.state.ws)
            this.state.ws.close();
    }

    connect = () => {
       var ws = new WebSocket("wss://beta.poleval.pl/api/view-progress-with-web-sockets/" + this.state.submissionId);

        ws.onopen = () => {
            this.setState({ws: ws});
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };

        ws.onmessage = (message) => {
            if(this.state.log){
                this.setState((state, props) => ({
                log: state.log.concat([message.data])
                }));
            } else {
                this.setState({
                log: [message.data]
                });
            };
            this.setState((state, props) => ({
                messages: state.messages.add(message.data),
            }));
          };
    };

    render() {
        return <div>
            <ListGroup>
            {this.state.log.map((line) => (
                    <ListGroup.Item key={line}>{line}</ListGroup.Item>
                ))
            }
            </ListGroup>
        </div>;
    }
}


const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubmissionLog));