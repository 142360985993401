import { Component } from 'react';
import Form from 'react-bootstrap/Form';

class ValidatedForm extends Component {
    state = {
        validated: false,
    }

    onSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({validated: true});

        if (this.form.checkValidity()) {
            this.props.onValidSubmit(event);
        }
    }

    reset = () => {
        this.setState({validated: false});
        this.form.reset();
    }

    render() {
        return (
            <Form ref={e => this.form = e} 
                noValidate 
                validated={this.state.validated} 
                onSubmit={this.onSubmit}>
                <fieldset disabled={this.props.disabled}>
                    {this.props.children}
                </fieldset>
            </Form>
        )
    }
}

export default ValidatedForm;