import React from 'react';
import Card from 'react-bootstrap/Card';

import classes from './Tag.module.css';


const tag = ( props ) => {
      return (
        <Card id={props.id} className={classes.TagCard}>
            <Card.Body>
              <Card.Title className={classes.TagTitle}><b>{props.name}</b></Card.Title> 
                <Card.Text className={classes.TagDescription}>
                  {props.description}
                </Card.Text>
            </Card.Body>
        </Card>
      )
};

export default tag;