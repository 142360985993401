import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

class Logout extends Component {

    componentDidMount () {
        this.props.keycloak.logout();
        this.props.onLogout();
    }
    render () {
        let redirectPath = "/";
        return <Redirect to={redirectPath} />;
    }
}


const mapStateToProps = state => {
    return {
        error: state.auth.error,
        keycloak: state.auth.keycloak
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

