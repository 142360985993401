import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';

import Header from '../../components/UI/Header/Header';
import Spinner from '../../components/UI/Spinner/Spinner';
import ChallengeThumbnail from "./ChallengeThumbnail/ChallengeThumbnail";
import axiosGonito from '../../axios-gonito';
import classes from './Challenges.module.css';

class Challenges extends Component {

    state = {
        currentChallenges: [],
        challenges:[],
        loading:true,
        error:false,
    };


    componentDidMount () {
        this.fetchChallenges(true);
        this.fetchChallenges();
    }

    fetchChallenges = (onlyCurrent=false) => {
        var type;
        var address;
        if (onlyCurrent){
            address = `list-challenges-by-tag/`+ process.env.REACT_APP_CURR_COMPETITION_TAG;
            type = `currentChallenges`;
        } else {
            address = `list-challenges`;
            type = `challenges`;
        }
        
        this.setState({loading: true});
        axiosGonito.get(address)
            .then(res => {
                const challenges = res.data;
                this.setState({
                    loading: false,
                    [type]: challenges,
                });
            })
            .catch(err => {
                this.setState({loading: false, error: true});
            });
    };


    render () {
        let spinner = <Spinner/>
        let header = null;
        if (!this.state.loading){
            spinner = null;
            header = <Header title="CHALLENGES" icon="fa fa-fw fa-trophy ConferenceIcon"></Header>
        }

        return (
            <>
                { header }
                <Container>
                <br/>
                <h1 className={classes.ChallengesHeader}>Current competitions</h1>
                    {spinner}
                    <CardDeck className={classes.CompCardDeck}>
                        {this.state.currentChallenges.map((challenge, index) => (
                            <ChallengeThumbnail
                                key={index}
                                name={challenge.name}
                                title={challenge.title}
                                archived={challenge.archived}
                                description={challenge.description}
                                imageUrl={challenge.imageUrl}
                                small={true}
                            />
                        ))}
                    </CardDeck>
                    <br/>
                    <h1 className={classes.ChallengesHeader}>Past competitions</h1>
                    {spinner}
                    <CardDeck className={classes.CompCardDeck}>
                        {this.state.challenges
                        .filter(challenge => !challenge.tags.some(tag => tag.name == process.env.REACT_APP_CURR_COMPETITION_TAG))
                        .map((challenge, index) => (
                            <ChallengeThumbnail
                                key={index}
                                name={challenge.name}
                                title={challenge.title}
                                archived={challenge.archived}
                                description={challenge.description}
                                imageUrl={challenge.imageUrl}
                            />
                        ))}
                    </CardDeck>
                {/* { spinner }
                <CardDeck className={classes.CardDeck}>
                    {this.state.challenges.map((challenge, index) => (
                            <ChallengeThumbnail
                                key={index}
                                name={challenge.name}
                                title={challenge.title}
                                archived={challenge.archived}
                                description={challenge.description}
                                imageUrl={challenge.imageUrl}
                                />
                    ))}
                </CardDeck> */}
               </Container>
            </>
        );
    }
}

export default Challenges;