import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import InfiniteScroll from 'react-infinite-scroll-component';

import classes from './Conferences.module.css';
import Conference from '../../components/Conference/Conference';
import Header from '../../components/UI/Header/Header';
import { transformDatetimeString } from '../../shared/utility';
import Spinner from '../../components/UI/Spinner/Spinner';
import axios from '../../axios-python';

class Conferences extends Component {

    state = {
        conferences:[],
        loading:true,
        error:false,
        count:0,
        page:0
    };
    

    componentDidMount () {
        this.fetchConferences(this.state.page);
    }

    fetchMoreData = () => {
        this.fetchConferences(this.state.page);
    }

    fetchConferences = (page) => {
        this.setState({loading: true});
        const token = localStorage.getItem('token');
        axios.get(`/conference?page=${page + 1}`,
        {
            // headers: {'Authorization': `Bearer ${token}`}
        })
        .then(res => {
            const conferences = res.data.results;
            const count =  res.data.count;
            this.setState({
                loading: false, 
                conferences: this.state.conferences.concat(conferences),
                count:count,
                page: page + 1
                });
        })
        .catch(err => {
            this.setState({loading: false, error:true});
        });
    };

     
    render () {
        let spinner = <Spinner/>
        let header = null;
        if (!this.state.loading){
            spinner = null;
            header = <Header title="CHALLENGES AND CONFERENCES" icon="fa fa-fw fa-users ConferenceIcon"></Header>
        }
       
        return (
            <div className={classes.Conference}>
                { header }
                <Container>
                { spinner }
                    <Row>
                    <InfiniteScroll
                        dataLength={this.state.conferences.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.count > this.state.conferences.length}
                        loader={<p>Loading...</p>}
                    >   
                {this.state.conferences.map(conference => (
                    <div key={conference.id}>
                        <Conference
                            key={conference.id}
                            name={conference.name}
                            link={conference.link}
                            date={transformDatetimeString(conference.date)}
                            submitAppDeadline={transformDatetimeString(conference.submit_app_deadline)}
                            reviewDeadline={transformDatetimeString(conference.review_deadline)}
                            image={conference.image}
                            description={conference.description}
                            location={conference.location}
                            keywords={conference.keywords} />
                    </div>
                ))}
                    </InfiniteScroll>
                    </Row>
               </Container>
            </div>
        );
    }
}

export default Conferences;