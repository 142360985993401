import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';

import Spinner from "../../components/UI/Spinner/Spinner";
import Header from '../../components/UI/Header/Header';
import * as actions from "../../store/actions";
import Tag from '../../components/Tag/Tag';
import classes from './Tags.module.css';

class Tags extends Component {
    componentDidMount () {
        this.props.onFetchTags();
    }

    render () {
        let tags = <Spinner/>

        if (this.props.tags){
            tags = (<>
                    <Header title="TAGS" icon="fa fa-fw fa-tags"></Header>
                    <Container>
                        <CardDeck className={classes.CardDeck}>
                            {this.props.tags.map(tag => (
                                <Tag
                                    key={tag.id}
                                    name={tag.name}
                                    description={tag.description} />
                            ))}
                        </CardDeck>
                    </Container>
                </>);
        }
       
        return (
            <div>
                {tags}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tags: state.tags.tags,
        loading: state.tags.loading,
        error: state.tags.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchTags: () => dispatch(actions.fetchTags())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tags));
