import { Component } from 'react';
import RestForm from '../RestForm/RestForm';
import Alert from 'react-bootstrap/Alert';

class RestAlertedForm extends Component {
    
    reset = () => {
        this.form.reset();
    }

    renderAlert () {
        if (this.props.error) {
            return <Alert variant="danger">{this.props.errorMsg}</Alert>;
        } else if (this.props.success) {
            return <Alert variant="success">{this.props.successMsg}</Alert>;
        } else {
            return <></>;
        }
    }

    render() {
        return (
            <RestForm ref={e => this.form = e} 
                sending={this.props.sending} 
                success={this.props.success}
                onValidSubmit={this.props.onValidSubmit}>
                {this.renderAlert()}
                {this.props.children}
            </RestForm>
        )
    }
}

export default RestAlertedForm;