import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';

import classes from './Submissions.module.css';
import axiosGonito from "../../axios-gonito";
import { transformDatetimeString } from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from 'react-bootstrap/Button'

import ColoredRow from '../../components/UI/ColoredRow/ColoredRow';


class Submissions extends Component {

    state = {
        submissions: [],
        tests: [],
        loading: true,
        error: false,
    };


    componentDidMount() {
        this.fetchSubmissions();
    }

    mapAllScores = () => {
        const submissions = this.state.submissions;
        submissions.map(el => (
            el.evaluations.map(evaluation =>
                el[this.concatenateTestData(evaluation.test)] = evaluation.score
            )
        ))
        return submissions;
    }

    concatenateTestData(testData) {
        return `${testData.name} ${testData.metric}`;
    }

    fetchSubmissions() {
        const token = localStorage.getItem('token');
        axiosGonito.get(
            `challenge-all-submissions/${this.props.challengeName}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {
                const submissions = res.data.submissions;
                const tests = res.data.tests;

                this.setState({
                    loading: false,
                    submissions: submissions,
                    tests: tests,
                });
                console.log(submissions);
            })
            .catch(err => {
                this.setState({ loading: false, error: true });
            });

    }

    makePublic(target, token){
        axiosGonito.get(
        `make-public/${target}`,
        {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         .then(res => {
             setTimeout(() => {
                this.fetchSubmissions();
           }, 1000);

        })
        .catch(err => {
            this.setState({loading: false, error:true});
        });
    }


    render() {
        let spinner = <Spinner />
        if (!this.state.loading) {
            spinner = null;
        }

        const submissions = this.mapAllScores();
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className={classes.Submissions}>
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th>Submitter</th>
                                        <th>When</th>
                                        <th>Description</th>
                                        <th>Rank</th>
                                        {this.state.tests.map((test, index) => (
                                            <th key={index}>{test.name} {test.metric} score</th>
                                        ))}
                                        <th>Tags</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {submissions.map((submission, index) => (
                                        <ColoredRow phase={submission.phase.name} key={index}>
                                            <td>{submission.submitter}</td>
                                            <td>{transformDatetimeString(submission.when)}</td>
                                            <td>{submission.description}</td>
                                            <td>{submission.rank}</td>
                                            {this.state.tests.map((test, index) => (
                                                <td key={index}>{submission[this.concatenateTestData(test)]}</td>
                                            ))}
                                            <td>
                                                {submission.tags.map((tag, index) => (
                                                    <><Badge variant="secondary">{tag.name}</Badge>&nbsp;</>
                                                ))}
                                            </td>
                                            <td>
                                                <Button variant="outline-dark">
                                                    <a href={'/gonito/q/' + submission.hash}>Details</a>
                                                </Button>
                                                {submission.isOwner && !submission.isPublic ?
                                                    <Button
                                                        variant="outline-info"
                                                        onClick={(e) => this.makePublic(submission.id,
                                                            localStorage.getItem('token'))}
                                                    >
                                                        Make public
                                                    </Button>
                                                    : <br />
                                                }
                                            </td>
                                        </ColoredRow>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        {spinner}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Submissions;
