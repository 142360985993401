export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}

export const transformDatetimeString = (dateTime) => {
    if (dateTime){
        const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
        const dateObj = new Date(dateTime);
        return dateObj.toLocaleDateString('en-GB', options);
    } else {
        return dateTime;
    }
}

export const transformDatetimeToRelativeDate = (dateTime) => {
    var date = new Date(dateTime);
    var dateNow = new Date();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;

    var elapsed = dateNow - date;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
         return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
         return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerDay * 7) {
        return Math.round(elapsed / msPerDay) + ' days ago';
    } else {
        return transformDatetimeString(dateTime);
    }
}