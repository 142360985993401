import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ToggleButton from 'react-bootstrap/ToggleButton'

import axiosGonito from "../../../axios-gonito";
import Spinner from "../../../components/UI/Spinner/Spinner";
import classes from './ChallengeLeaderboard.module.css';

import ColoredRow from '../../../components/UI/ColoredRow/ColoredRow';

class ChallengeLeaderboard extends Component {

     state = {
         leaderboardEntries:[],
         leaderboardTests:[],
         loading:true,
         error:false,
         filterResults: false
     };

     componentDidMount() {
         this.fetchLeaderboard();
     }

     fetchLeaderboard() {
         axiosGonito.get(`leaderboard/${this.props.challengeName}`)
             .then(res => {
                const leaderboard = res.data.entries;
                const tests = res.data.tests;
                this.setState({
                    loading: false,
                    leaderboardEntries: leaderboard,
                    leaderboardTests: tests,
                    });
            })
            .catch(err => {
                this.setState({loading: false, error:true});
            });
     }

     mapAllScores = () => {
         const leaderBoardEntries = this.state.leaderboardEntries;
         leaderBoardEntries.map(el => (
             el.evaluations.map(evaluation =>
                 el[this.concatenateTestData(evaluation.test)] = evaluation.score
             )
         ))
        return leaderBoardEntries;
     }

     sortReverseByScore(array) {
         // it sorts rows by the score from the last column
         // as it is the most relevant one
        return array.sort((a, b) => {
            const key = this.concatenateTestData(this.state.leaderboardTests[this.state.leaderboardTests.length - 1]);
            const x = parseFloat(a[key]); const y = parseFloat(b[key]);
            // return ((x < y) ? 1 : ((x > y) ? -1 : 0));
            return 0
        });
     }

     concatenateTestData(testData) {
         return `${testData.name} ${testData.metric}`;
     }


    render () {
         let spinner = <Spinner key={Math.random()}/>
         let sortedLeaderboard = [];
         if (!this.state.loading){
             spinner = null;
             const mappedScoresLeaderBoard = this.mapAllScores();
             sortedLeaderboard = this.sortReverseByScore(mappedScoresLeaderBoard);
         }

         return (
             <Container>
                 <Row>
                     <Col sm={12}>
                         <div className={classes.ChallengeLeaderboard}>
                             <Table responsive bordered hover>
                                  <thead>
                                    <tr>
                                      <th>                                                               <ToggleButton
                                          id="toggle-check"
                                          type="checkbox"
                                          variant="outline-primary"
                                          checked={this.state.filterResults}
                                          value="1"
                                          onChange={(e) => this.setState({
                                                            filterResults: e.currentTarget.checked})}
                                        >
                                          Contest only
                                        </ToggleButton></th>
                                      <th>Submitter</th>
                                      <th>Affiliation</th>
                                      <th>Best Submission Description</th>
                                      <th>Task Version</th>
                                      <th>Entries</th>
                                        {this.state.leaderboardTests.map((test, index) => (
                                           <th key={index}>{test.name} {test.metric} score</th>
                                        ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                       {sortedLeaderboard.filter(entry => !this.state.filterResults || ['poleval-2021', 'poleval-2022'].includes(entry.phase.name))
                                       .map((leaderboardEntry, index) => (
                                            <ColoredRow phase={leaderboardEntry.phase.name} key={index}>
                                               <td>{index + 1}</td>
                                               <td>{leaderboardEntry.submitter}</td>
                                               <td>{leaderboardEntry.team}</td>
                                               <td>{leaderboardEntry.description}</td>
                                               <td>{Array.from(leaderboardEntry.version).join('.')}</td>
                                               <td>{leaderboardEntry.times}</td>
                                               {this.state.leaderboardTests.map((test, index) => (
                                                    <td key={index}>{leaderboardEntry[this.concatenateTestData(test)]}</td>
                                               ))}
                                           </ColoredRow>
                                        ))}
                                  </tbody>
                             </Table>
                         </div>
                         {spinner}
                    </Col>
                 </Row>
             </Container>
         );
    }
}


export default ChallengeLeaderboard;