import React from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import classes from './Conference.module.css';


const conference = ( props ) => {
    const keywords = props.keywords.map((keyword, index) => (
        <span><Badge key={index} variant="secondary">{ keyword }</Badge>{' '}</span>
      ))
      return (
      <div className={classes.Conference}>
          <Card id={props.id}>
            <Card.Img variant="top" src={props.image} />
            <Card.Body>
              <Card.Title><b>{props.name}</b></Card.Title> 
                <Card.Text>
                  {props.description}
                </Card.Text>
                <Card.Text>
                    <b>WHERE:</b> {props.location}
                </Card.Text>
                <Card.Text>
                    <b>WHEN:</b> {props.date}
                </Card.Text>
                { props.submitAppDeadline ? 
                <Card.Text>
                <b>FINAL SUBMISSION:</b> {props.submitAppDeadline}
                </Card.Text> : <></> }
                { props.reviewDeadline ? 
                <Card.Text>
                <b>REVIEW DEADLINE:</b> {props.reviewDeadline}
                </Card.Text> : <></> }
                <Card.Text>
                  { keywords }
                </Card.Text>
                <Button href={props.link} className={classes.Button} variant="primary" target="_blank">Go to website</Button>
            </Card.Body>
        </Card>
      </div>
      )
};

export default conference;