import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormComponent from '../../../components/UI/Form/FormComponent/FormComponent';
import RestAlertedForm from '../../../components/UI/Form/RestAlertedForm/RestAlertedForm';
import { connect } from 'react-redux';

class SubmissionForm extends FormComponent {
    state = {
        form: {
            description: '',
            tags: [],
        }
    }

    renderTags () {
        if (this.props.tags) {
            return this.props.tags.map((tag) => (
                <option key={tag.name} title={tag.description}>{tag.name}</option>
            ));
        } else {
            return <></>;
        }
    }

    buildErrorMsg = () => {
        let errorMsg = "";

        if (!this.props.error) {
            return errorMsg;
        }

        errorMsg = `Problem with submitting solution to server. You were logged out. Please, try again. Error info:\n`;

        if (this.props.error.message) {
            errorMsg = errorMsg + `${this.props.error.message}.`;
        } else if (this.props.error.detail) {
            errorMsg = errorMsg + `${this.props.error.detail}`;
        } else if (this.props.response) {
            errorMsg = errorMsg + `Error code: ${this.props.error.response.status}.`;
        } else {
            errorMsg = errorMsg;
        }

        return errorMsg;
    }

    render () {
        return (
            <RestAlertedForm 
                sending={this.props.submitting} 
                success={this.props.success} 
                error={this.props.error}
                successMsg="Solution uploaded."
                errorMsg={this.buildErrorMsg()}
                onValidSubmit={this.onSubmit}>

                {this.props.children}

                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control name='description' as="textarea" 
                      placeholder="Enter description. It will appear on the submissions list. Description of your best result will also become visible on the leaderboard." rows="3" 
                      defaultValue={this.state.form.description}
                      onChange={this.handleChange}
                      required={true} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tags</Form.Label>
                      <Form.Control name="tags" as="select" multiple custom
                        onChange={this.handleChange} >
                          <option value="" disabled>Choose solution tags (optional)</option>
                          {this.renderTags()}
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </RestAlertedForm>
        );
    }
}

const mapStateToProps = state => {
    return {
        tags: state.tags.tags,
    }
};

export default connect(mapStateToProps, null)(SubmissionForm);