import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from '../../../components/UI/Spinner/Spinner';
import ReactMarkdown from 'react-markdown'

import axios from '../../../axios-python';

class ExternalMarkdown extends Component {
    state = {
        markdown: '',
        loading: true
    }

    componentDidMount() {
        this.fetchAndExtractMarkdown();
    }

    extractMarkdown = (result) => {
        if (!this.props.rest) {
            return result.data;
        } else if (result.data.results.length !== 0) {
            return result.data.results[0].markdown;
        } else {
            return '# Problem with extracting fetched data';
        }
    }

    fetchAndExtractMarkdown = () => {
        axios.get(this.props.url)
        .then(res => {
            const markdown = this.extractMarkdown(res).toString();
            
            this.setState({ markdown: markdown, loading: false });
        })
        .catch(_ => {
            this.setState({ markdown: '# Problem with fetching data', loading: false });
        });
    }

    render () {
        if (this.state.loading) {
            return <Spinner />;
        } else {
            return (
                <Container>
                    <Row>
                        <Col sm={12}>
                            <ReactMarkdown escapeHtml={false}>
                                { this.state.markdown }
                            </ReactMarkdown>
                        </Col>
                    </Row>
                </Container>
            );
        }
    }
    
};

export default ExternalMarkdown;