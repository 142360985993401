import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import classes from './Layout.module.css';
import SideBar from '../../components/Navigation/SideBar/SideBar';
import NavBar from '../../components/Navigation/NavBar/NavBar';

class Layout extends Component {
 
    state = {
        sideBarExpanded:true
    }
 
    sideBarToggleHandler = () => {
        this.setState({
            sideBarExpanded: !this.state.sideBarExpanded
          })
    }

    render () {
        let navigation = null;
        let mainClassName = null;

        navigation = <NavBar isAuth={this.props.isAuthenticated} />
        /* Old navigation handling - navbar only on home page sidebar on all another

        if(!this.props.isAuthenticated && this.props.location.pathname === "/"){
            navigation = <NavBar />
        }else if(this.props.isAuthenticated){
            navigation = <>
                <SideBar sideBarToggleHandler={this.sideBarToggleHandler} />
                <Button className={classes.AuthButton}>
                    <NavLink to="/logout">Logout</NavLink>
                </Button>
             </>
        }else if(this.props.location.pathname !== "/"){
            navigation = <>
                <SideBar sideBarToggleHandler={this.sideBarToggleHandler} />
                <Button className={classes.AuthButton}>
                    <NavLink to="/authorize">Login/Register</NavLink>
                </Button>
             </>
        }
        if(this.state.sideBarExpanded && this.props.location.pathname !== "/"){
            mainClassName = classes.ContentSideBarExpanded
        }else if(this.props.location.pathname !== "/"){
            mainClassName = classes.ContentSideBarCollapsed
        }
        */
        return (
            <>
                {navigation}
                <p></p>
                <main className={mainClassName}>
                    {this.props.children}
                </main>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token != null,
        username: state.auth.username
    }
}

export default connect(mapStateToProps, null)(withRouter(Layout));
