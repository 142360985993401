import {Component} from "react";
import jwt from 'jwt-decode';


class ShowRoleBasedComponent extends Component {

    getRolesFromToken = () => {
        const token = localStorage.getItem("token");
        return token ? jwt(token).realm_access.roles : null;
    }

    render () {
        const userRoles = this.getRolesFromToken();
        let content = null;
        if (userRoles && userRoles.includes(this.props.roleRequired)) {
            content = this.props.children;
        }
        return (
            <>{content}</>
        );
    }
}

export default  ShowRoleBasedComponent;