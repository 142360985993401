import { Component } from 'react';
import ValidatedForm from '../ValidatedForm/ValidatedForm';

class RestForm extends Component {
    componentDidUpdate = (prevProps, _) => {
        if (this.props.success && !prevProps.success) {
            this.reset();
        }
    }

    reset = () => {
        this.form.reset();
    }

    render() {
        return (
            <ValidatedForm ref={e => this.form = e} 
                disabled={this.props.sending} 
                onValidSubmit={this.props.onValidSubmit}>
                {this.props.children}
            </ValidatedForm>
        )
    }
}

export default RestForm;