import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';


const initialState = {
    success: null,
    error: null,
    submitting: false,
    submissionId: null
}

const submitSolutionStart = (state, _) => {
    return updateObject(state, {
        success: null, 
        error: null, 
        submitting:true});
}

const submitSolutionSuccess = (state, _) => {
    return updateObject(state, {
        success: true,
        error:null,
        submitting: false,
        submissionId: _['submissionId'],
        submission: _
    })
}

const submitSolutionFail = (state, action) => {
    return updateObject(state, {
        success: false, 
        error:action.error, 
        submitting: false})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_SOLUTION_START: return submitSolutionStart(state, action);
        case actionTypes.SUBMIT_SOLUTION_SUCCESS: return submitSolutionSuccess(state, action);
        case actionTypes.SUBMIT_SOLUTION_FAIL: return submitSolutionFail(state, action);
        default:
            return state;
    }
}

export default reducer;