import Keycloak from 'keycloak-js';
import axios from 'axios';

import axiosPolEval from '../../axios-python';
import axiosGonito from '../../axios-gonito';

import * as actionTypes from './actionTypes';



export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token, username, keycloak) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        username: username,
        keycloak: keycloak
    }
}

export const authFail = (err, keycloak) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: err,
        keycloak: keycloak
    }
}

export const logout = (keycloak) => {
    if(keycloak)
        keycloak.logout();
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const checkAuthTimeout = (expirationTime) => {
    const expTime = new Date(expirationTime * 1000) - new Date();
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expTime)
    }
}

export const createUsers = (userData, keycloak) => {
        return dispatch => {
            const django_token = localStorage.getItem('token');
            axiosPolEval.post(
                    '/auth/users/',
                    {
                        username: userData.preferred_username,
                        email: userData.email,
                        keycloak_id: userData.user_id,
                        first_name: userData.given_name,
                        last_name: userData.family_name
                    },
                    {
                        headers: {
                        // 'Authorization': `Bearer ${django_token}`
                        }
                    }
                )
                .then(
                    axiosGonito.get('/add-user',
                    {
                        headers: 
                            {   'Accept': 'application/json',
                                'Authorization': `Bearer ${keycloak.token}`,
                            }
                    }).then(
                        dispatch(authSuccess(keycloak.idToken,keycloak.idTokenParsed.user_id, keycloak))
                    )
                    .catch(err => {
                        dispatch(authFail(err, keycloak));
                    })
                )
                .catch(err => {
                    dispatch(authFail(err, keycloak));
                })
        
            }
  }
  

const checkIfUserExists = (userData, keycloak) => {
    return dispatch => {
        // axios.get(process.env.REACT_APP_POLEVAL_URL + 'auth/users/' + userData.preferred_username)
        const django_token = localStorage.getItem('token');
        axios.get(process.env.REACT_APP_POLEVAL_URL + 'auth/users/' + userData.preferred_username,
            {
                headers: {
                    'Authorization': `Bearer ${django_token}`
                }
            }
        )
        .then(() => {
            dispatch(authSuccess(keycloak.idToken,keycloak.idTokenParsed.preferred_username, keycloak))
        }
        )
        .catch(err => {
                if(err.response.status === 401 || err.response.status === 404){ // there is no such user yet or it does not have an acces to django yet
                    dispatch(createUsers(userData, keycloak));
                }else{
                    dispatch(authFail(err));
                    dispatch(logout(keycloak));
                }
        })
    }
}


export const auth = () => {
    return dispatch => {
        dispatch(authStart());
        const keycloakPath = process.env.NODE_ENV === 'development' ? '/keycloak-dev.json' : '/keycloak.json';
        const keycloak = Keycloak(keycloakPath);
        keycloak.init({onLoad: 'login-required', checkLoginIframe: false, flow: 'implicit', responseType: 'code id_token token'})
        .then(() => {
          const expirationDate = new Date(keycloak.idTokenParsed.exp * 1000);
          localStorage.setItem('token', keycloak.token);
          localStorage.setItem('expirationDate',expirationDate);
          localStorage.setItem('username', keycloak.idTokenParsed.preferred_username);
          dispatch(checkIfUserExists(keycloak.idTokenParsed, keycloak));
          dispatch(checkAuthTimeout(keycloak.idTokenParsed.exp));
        }).catch(err => {
            dispatch(authFail(keycloak.error));
        })

    }
}  

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECTION_PATH,
        path: path  
    }
}

export const authStateCheck = (keycloak) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(!token){
            dispatch(logout(keycloak));
        }else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const username = localStorage.getItem('username');
            if (expirationDate > new Date()){
                if(!keycloak){
                    keycloak = new Keycloak();
                    keycloak.init()
                }
                dispatch(authSuccess(token, username, keycloak))
                dispatch(checkAuthTimeout(expirationDate.getTime()/1000))
            }else{
                dispatch(logout(keycloak))
            }
        }
    }
}
