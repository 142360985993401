import React, { Component } from 'react';
import ExternalMarkdown from '../../UI/ExternalMarkdown/ExternalMarkdown';

class PrizesMain extends Component {

    render () {
        return (
            <>
                <ExternalMarkdown url="/static_content/prizes" rest />
            </>
        );
    }
}

export default PrizesMain;