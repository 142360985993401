import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    challenge: null,
    error: null,
    loading: true,
}

const fetchChallengeStart = (state, action) => {
    return updateObject(state, {error: null, loading:true});
}

const fetchChallengeSuccess = (state, action) => {
    return updateObject(state, {
        challenge: action.challenge,
        error:null,
        loading: false,
    })
}

const fetchChallengeFail = (state, action) => {
    return updateObject(state, {error:action.error, loading: false})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CHALLENGE_START: return fetchChallengeStart(state, action);
        case actionTypes.FETCH_CHALLENGE_SUCCESS: return fetchChallengeSuccess(state, action);
        case actionTypes.FETCH_CHALLENGE_FAIL: return fetchChallengeFail(state, action);
        default:
            return state;
    }
}

export default reducer;