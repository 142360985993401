export const  AUTH_START =  'AUTH_START'
export const  AUTH_SUCCESS =  'AUTH_SUCCESS'
export const  AUTH_FAIL =  'AUTH_FAIL'
export const  AUTH_LOGOUT =  'AUTH_LOGOUT'
export const  CREATE_USER_SUCCESS =  'CREATE_USER_SUCCESS'
export const  CREATE_USER_FAIL =  'CREATE_USER_FAIL'

export const SET_AUTH_REDIRECTION_PATH = 'SET_AUTH_REDIRECTION_PATH'

export const  FETCH_CHALLENGE_START =  'FETCH_CHALLENGE_START'
export const  FETCH_CHALLENGE_SUCCESS =  'FETCH_CHALLENGE_SUCCESS'
export const  FETCH_CHALLENGE_FAIL =  'FETCH_CHALLENGE_FAIL'

export const  FETCH_DATASET_START =  'FETCH_DATASET_START'
export const  FETCH_DATASET_SUCCESS =  'FETCH_DATASET_SUCCESS'
export const  FETCH_DATASET_FAIL =  'FETCH_DATASET_FAIL'

export const  FETCH_TAGS_START =  'FETCH_TAGS_START'
export const  FETCH_TAGS_SUCCESS =  'FETCH_TAGS_SUCCESS'
export const  FETCH_TAGS_FAIL =  'FETCH_TAGS_FAIL'

export const  SUBMIT_SOLUTION_START =  'SUBMIT_SOLUTION_START'
export const  SUBMIT_SOLUTION_SUCCESS =  'SUBMIT_SOLUTION_SUCCESS'
export const  SUBMIT_SOLUTION_FAIL =  'SUBMIT_SOLUTION_FAIL'

