import React from 'react';
import Card from 'react-bootstrap/Card';
import ReactMarkdown from 'react-markdown'

import classes from './News.module.css';
import { transformDatetimeToRelativeDate } from '../../shared/utility';


const news = ( props ) => {
      return (
        <div className={classes.News}>
          <Card className={classes.NewsCard}>
            <Card.Body>
                <Card.Title><b>{props.title}</b></Card.Title>
                <Card.Text>
                  <ReactMarkdown escapeHtml={false}>
                    {props.text}
                  </ReactMarkdown>
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">{transformDatetimeToRelativeDate(props.date)}</Card.Footer>
        </Card>
    </div>
      )
};

export default news;