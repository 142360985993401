import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardDeck from "react-bootstrap/CardDeck";

import classes from './HomeNews.module.css';
import axios from '../../../axios-python';
import News from '../../../components/News/News';

class HomeNews extends Component {

    state = {
        news: [],
        loading: true,
        page: 0,
        count: 0,
        error: false
    }

    componentDidMount () {
        this.fetchNews(this.state.page);
    }

    fetchMoreNews  = () => {
        this.fetchNews(this.state.page);
    }

    fetchNews = (page) => {
        this.setState({loading: true});
        axios.get(`/news/?page=${page + 1}`)
        .then(res => {
            const news = res.data.results;
            const count = res.data.count;

            this.setState({
                loading: false, 
                news: this.state.news.concat(news),
                count: count,
                page: page + 1
                });
        })
        .catch(err => {
            this.setState({loading: false, error: true});
        });
    };

    render () {
        return (
            <Container>
                    <Row>
                        <Col sm={12} className={classes.NewsHeaderCol}>
                            <h1 className={classes.HomeNewsHeader}>PolEval :: News</h1>
                        </Col>
                    </Row>

                    <Row className={classes.NewsRow}
                        id="home-news">
                            <Col sm={12} className={classes.NewsHeaderCol}>
                                <CardDeck>
                                    <InfiniteScroll
                                        scrollableTarget="home-news"
                                        dataLength={this.state.news.length}
                                        next={this.fetchMoreNews}
                                        hasMore={this.state.count > this.state.news.length} >

                                    <div className="container-fluid">
                                        <div className="row flex-row flex-nowrap">
                                        {this.state.news.map(news => (
                                            <News key={news.id}
                                                title={news.title}
                                                date={news.date}
                                                text={news.text} />
                                        ))}
                                        </div>
                                    </div>
                                </InfiniteScroll>
                            </CardDeck>
                        </Col>
                    </Row>
            </Container>
        );
    }
}

export default HomeNews;