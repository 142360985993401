import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Header.module.css';

const header = (props) => {
    const attachedOuterClasses = [classes.OuterLine, classes.Icon]
    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <div className={classes.Header} >
                            <h1 className={classes.Title}>{props.title}</h1>
                        <div className={classes.HeaderIcon}>
                            <span className={attachedOuterClasses.join(' ')}></span>
                            <span className={props.icon} style={{fontSize: '2.25em'}}/>
                            <span className={attachedOuterClasses.join(' ')}></span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
};

export default header;
