import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Figure from 'react-bootstrap/Figure';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal'
import Spinner from "../../../components/UI/Spinner/Spinner";
import Button from 'react-bootstrap/Button';

import classes from './Submission.module.css';
import GitSubmissionForm from '../GitSubmissionForm/GitSubmissionForm';
import FileSubmissionForm from '../FileSubmissionForm/FileSubmissionForm';
import SubmissionLog from '../SubmissionLog/SubmissionLog';
import * as actions from "../../../store/actions";
import * as actionTypes from '../../../store/actions';


class Submission extends Component {

    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        challengeName: this.props.match.params.challengeName,
        submissionId: this.props.match.params.submissionId,
        showModal: false
    }

    onSubmit = (form, type) => {
        const submissionData = {
            challengeName: this.props.challenge.name,
            formData: form,
            type: type,
        }

        this.props.onSubmitSolution(submissionData, this.props.token, this.handleShow);
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this.props.history.push('/authorize');
        } else {
            this.props.onFetchChallenge(this.state.challengeName);
            this.props.onFetchTags();
            this.setState({
            submissionId: null
            });
        }
    }

    componentDidUpdate(){
        if(this.props.submitSuccess){
        }
    }

    handleClose = () => this.setState({
                            showModal: false
                            });

    handleShow = () => this.setState({
                        showModal: true
                        });

    goBack = () => {
        this.handleClose();
       setTimeout(() => {
           this.props.history.push('/challenge/' + this.props.challenge.name);
       }, 1000);
    }

    render() {
        let submission = <Spinner />;
        let error = <></>;

        if (this.props.loadingError) {
            submission = <></>;
            error = <Alert variant="danger">Problem with loading challenge and tags data. Reload the page.</Alert>;
        } else if (!this.props.loading) {
            let archived = <><span>&#183;</span> &nbsp;<Badge variant="secondary"> ARCHIVED</Badge></>
            if (this.props.challenge.archived === false) {
                archived = null;
            }
            
            let imageUrl = this.props.challenge.imageUrl == null ? "/media/conference/nlp2.jpg" :
            process.env.REACT_APP_GONITO_URL + this.props.challenge.imageUrl.replace("/api/", "").replace("/api", "");
            
            submission = (
                <Card className={classes.SubmissionCard}>
                    <Card.Header>
                        <Figure className={classes.Figure}>
                            <Figure.Image
                                src={imageUrl}
                                fluid
                                responsive="true"
                            />
                            <Figure.Caption className={classes.FigureCaption}>
                                {this.props.challenge.title}&nbsp;
                                {archived}
                            </Figure.Caption>
                        </Figure>
                    </Card.Header>
                    <Card.Body>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="file">
                            <Row>
                                <Col>
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="file">File upload</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="git">Git repository</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={classes.SubmissionContent}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="file">
                                                <FileSubmissionForm
                                                    challengeName={this.props.challenge.name}
                                                    submitting={this.props.submitting}
                                                    success={this.props.submitSuccess}
                                                    error={this.props.submitError}
                                                    onSubmit={e => this.onSubmit(e, 'file')} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="git">
                                                <GitSubmissionForm
                                                    submitting={this.props.submitting}
                                                    success={this.props.submitSuccess}
                                                    error={this.props.submitError}
                                                    onSubmit={e => this.onSubmit(e, 'git')} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Card.Body>
                    <Modal show={this.state.showModal} onHide={this.goBack} centered size="lg">
                         <Modal.Header closeButton>
                             <Modal.Title>Submission log</Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                             <SubmissionLog submissionId={this.props.submissionId}
                             isShown={this.state.showModal}/>
                         </Modal.Body>
                         <Modal.Footer>
                             <Button variant="secondary" onClick={this.goBack}>
                                 Close
                             </Button>
                         </Modal.Footer>
                        </Modal>

                </Card>
            )
        }

        return (
            <>
                <Container>
                    <h4>Enter your submission data</h4>
                    {error}
                    {submission}
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        challenge: state.challenge.challenge,
        isAuthenticated: state.auth.token != null,
        loading: state.challenge.loading || state.tags.loading,
        loadingError: state.challenge.error || state.tags.error,
        submitting: state.submission.submitting,
        submitError: state.submission.error,
        submitSuccess: state.submission.success,
        token: state.auth.token,
        submissionId: state.submission.submissionId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchChallenge: (challengeName) => dispatch(actions.fetchChallenge(challengeName)),
        onFetchTags: () => dispatch(actions.fetchTags()),
        onSubmitSolution: (submissionData, token, handleShow) => dispatch(actions.submitSolution(submissionData, token,
        handleShow))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Submission));