import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import classes from './ChallengeThumbnail.module.css';


class ChallengeThumbnail extends Component {

      challengeClickHandler () {
          this.props.history.push('/challenge/'+ this.props.name);
      }

      render () {
          let archived = <Badge variant="secondary">ARCHIVED</Badge>
          if(this.props.archived === false){
              archived = null;
          }

          let imageUrl = this.props.imageUrl == null ? "/media/conference/nlp2.jpg" :
                                process.env.REACT_APP_GONITO_URL + this.props.imageUrl.replace("/api/", "").replace("/api", "");

          return (
              <Card className={this.props.small ? classes.SmallerChallengeCard : classes.BiggerChallengeCard} onClick={() => {
                  this.challengeClickHandler()
              }}>
                  <Card.Img variant="top" src={ imageUrl }/>
                  <Card.Body>
                      <Card.Title><b>{this.props.title}</b></Card.Title>
                      <Card.Text className={classes.ChallengeDescription}>
                          {this.props.description}
                      </Card.Text>
                      <Card.Text>
                          {archived}
                      </Card.Text>
                  </Card.Body>
              </Card>
          )
      }
};

export default withRouter(ChallengeThumbnail);
